import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  LBL_NOT_STARTED,
  LBL_PARTIAL,
  LBL_SUBMITTED,
  SUBMIT_STATUS,
  TABLE_QUESTION,
  TABLE_SUB_THEME,
  UPDATE_STATUS,
  PILLAR_BG_COLORS,
  PILLAR_BG_COLORS_LIGHT,
} from "../../constants";
import { getTopics, submitFeedback, updateFeedback } from "../../service";
import BackdropLoader from "../common/BackdropLoader";
import Checkbox from "../common/Checkbox";
import Modal from "../common/Modal";
import Category from "../Feedback/Category";
import FeedbackQuestion from "../Feedback/FeedbackQuestion";
import Group from "../Feedback/Group";
import Topic from "../Feedback/Topic";
import EditableTextButton from "../common/EditableTextButton";
import { getUser, clearUser, isAdminRole } from "../../service/Auth";
import { FEEDBACK_ROUTE } from "../../constants/Routes";

import { Steps } from "../common/Steps";
import Slider from "../common/Slider";
import { mergeDeep } from "../utility";
import EditableText from "../common/EditableText";
import { IconEye } from "../common/IconEye";
import { IconPencil } from "../common/IconPencil";
import { themeDefault } from "../../constants/themeSettings";
import TextCard from "../common/TextCard";
import { Text } from "recharts";

const FORMTITLE = "Self-Assessment Questionnaire";

const ERROR_MESSAGE = "Error while uploading your data. Please contact Admin.";
const SUBMIT_SUCCESS_MESSAGE = "Your answers have been submitted successfully!";
const SAVE_CONTINUE_SUCCESS_MESSAGE =
  "Your answers have been saved successfully!";
const UPLOAD_MESSAGE = "Uploading your data. Please wait...";

const styles = {
  divider_line: "border-b border-50",
};

const FORM_BG_COLOR = "bg-slate-50";

const stickyClassHeader = "sticky top-10 z-40";
const stickyClassSteps = "sticky top-24 z-40";
const stickyClassEditEye = "fixed top-24 right-1 z-50";

const THEME_LABEL = "theme"; // Stores current in-progress theme
const FEEDBACK_ANSWERS = "feedback"; // Stors actual feedback/answers of questions
const FEEDBACK_STATUS = "feedbackStatus"; // Stores each subtheme status

// To save feedback with this key. {'theme-title': {'sub-theme-title':{'q1-title': val},... }}
const SAVE_FEEDBACK_BY_KEY = "title"; //"objectId"/"title";

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphaArr = alpha.map((x) => String.fromCharCode(x));

// To show question number w.r.t Selected pillar
let questionNumber = 1;

const NewFeedbackForm = (props) => {
  const {
    data = [],
    defaultFormData = {},
    groupsList,
    isDisable,
    defaultSelectedStep = {},

    activeStepIndex,
    selectedStepData = {},
    handleOnStepClick,

    handleOnTextChange,
    handleOnEnterSave,
    newQuestionText,
    showNewQuestionBlockForId,
    handleNewQuestionTextChange,
    handleNewQuestionSave,
    handleDiscardNewQuestion,
    handleShowNewQuestionClick,
    isEditModeEnable,
    handleOnEditModeClick,
  } = props;

  const [formData, setFormData] = useState(defaultFormData || {});

  const [isLoading, setLoader] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [alertMesssage, setAlertMesssage] = useState("");
  const [severity, setSeverity] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeInputBox, setActiveInputBox] = useState(null);
  const [exit, setExit] = useState(null);
  const prevInputValueRef = useRef(null);
  //console.log("imp", prevInputValueRef.current);
  //console.log("totalAnsweredCount", totalAnsweredCount);

  const user = getUser();
  const navigate = useNavigate();

  const navigateTo = (path) => navigate(path);

  // Acticate/set the 1st partial filled pillar on page load.
  useEffect(() => {
    if (formData?.feedback && formData.feedback.length) {
      const partialIndex = formData.feedback.findIndex((data, index) => {
        return getPillarCompletionPercentage(data) < 100;
      });

      if (partialIndex > 0) {
        onStepClickChange(partialIndex);
      }
    }
  }, []);

  const handleCancelLogic = (
    tableName,
    columnName,
    subThemeindex,
    queIndex
  ) => {
    // Based on clicked text update selectedStep object state
    //console.log("selectedStepData", selectedStepData, queIndex);
    if (tableName === TABLE_QUESTION) {
      console.log(
        "if",
        selectedStepData.sub_theme[subThemeindex].question[queIndex]
      );
    }
  };
  const randomString = (chars, length) => {
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }

    return result;
  };
  const handleSaveClick = () => {
    // Save the input value and exit edit mode
    setIsEditing(false);
    setActiveInputBox(null);
  };

  const handleCancelClick = () => {
    // Discard changes and exit edit mode

    setIsEditing(false);
    setActiveInputBox(null);
  };

  // Return true if form is submitted
  const isStatusSubmitted = () => {
    return formData["status"] === SUBMIT_STATUS;
  };

  const onTextChange = (
    objectId,
    tableName,
    columnName,
    value,
    subThemeindex,
    queIndex
  ) => {
    handleOnTextChange(
      objectId,
      tableName,
      columnName,
      value,
      subThemeindex,
      queIndex
    );
  };

  const handleClick = () => {
    console.log("");
  };

  // const handleOnStepClick = (stepIndex, stepData) => {
  //   if (data && stepIndex < data.length) {
  //     const stepDataObj = data && data[stepIndex];
  //     // setselectedStepData(stepDataObj);
  //     handleSelectedStepData(stepDataObj)
  //     setselectedStepIndex(stepIndex);
  //   }
  // };

  const getSelectedStepIndex = () => {
    const index = data.findIndex(
      (stepObj) => stepObj.objectId === selectedStepData.objectId
    );
    console.log("index ", index);
    return index;
  };

  // return total number of questions.
  const countTotalQuestions = (arr) => {
    const count =
      arr &&
      arr.sub_theme.flatMap((sub) => {
        return sub.question.filter((que) => {
          if (que) return "title" in que;
        });
      });

    return (count && count.length) || 0;
  };

  // return pillar completion percentage
  const getPillarCompletionPercentage = (step) => {
    let pillarCompletPercent = 0;
    const formDataPillars = (formData && formData.feedback) || [];

    const pillarObj = formDataPillars.filter(
      (pillar) => pillar.sequence === step.sequence
    )[0];
    if (pillarObj) {
      const themeTotalQuestions = countTotalQuestions(pillarObj);
      pillarCompletPercent =
        Math.round((pillarObj.answeredQuestion / themeTotalQuestions) * 100) ||
        0;
    }

    return pillarCompletPercent;
  };

  const stepContainer = () => {
    const themeAnsweredQuestions =
      (formData &&
        formData.feedback &&
        formData.feedback[activeStepIndex] &&
        formData.feedback[activeStepIndex].answeredQuestion) ||
      0;

    const themeTotalQuestions = countTotalQuestions(data[activeStepIndex]);

    return (
      <div className={`${stickyClassSteps}`}>
        <div className={`border-slate-200 bg-slate-200 border rounded-md`}>
          <div className="flex justify-around items-center px-2">
            {data.map((step, index) => {
              return (
                <TextCard
                  step={step}
                  index={index}
                  data={data}
                  stepCompletPercentage={getPillarCompletionPercentage(step)}
                  activeStepIndex={activeStepIndex}
                  selectedStepData={selectedStepData}
                  handleOnStepClick={onStepClickChange}
                />
              );
            })}
          </div>
        </div>
        <div className={`flex justify-end px-1`}>
          <p className="bg-amber-100 px-2">
            {`You have answered: ${themeAnsweredQuestions} / ${themeTotalQuestions}`}
          </p>
        </div>
      </div>
    );

    // This is old steper component - TODO - Can remove once no need of this
    return (
      <div
        className={`flex justify-center border-slate-200 bg-slate-200 rounded-full ${stickyClassSteps}`}
      >
        <Steps
          data={data}
          activeStepIndex={activeStepIndex}
          selectedStepData={selectedStepData}
          handleOnStepClick={onStepClickChange}
        />
      </div>
    );
  };

  const formHeading = () => {
    const isBackBtnEnable = activeStepIndex > 0;

    return (
      <div
        className={`${stickyClassHeader} flex justify-center items-center rounded bg-slate-200 pt-1 px-16`}
      >
        <div>
          {isBackBtnEnable && (
            <button
              className={`flex px-3 py-1 items-center rounded text-white font-semibold text-sm ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} tooltip tooltip-right`}
              data-tip="Go to Previous Role"
              onClick={handleBackStepBtnClick}
            >
              <svg
                class="w-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Back
            </button>
          )}
        </div>
        <div
          className={`py-5 pb-6 text-2xl font-bold tracking-wide ${themeDefault.text_color_dark} flex-1 justify-center`}
        >
          {FORMTITLE}
        </div>
      </div>
    );
  };

  function countQuestions(arr) {
    const count = arr.sub_theme.flatMap((sub) => {
      return sub.question.filter((que) => {
        return "value" in que;
      });
    });

    return count.length;
  }

  const handleSelection = (value) => {
    // Old data stucture - feedback: {'key':value,'key':value,...}
    // setFormData({ ...formData, [name]: value });
    let countedQuestion = countQuestions(value.feedback[activeStepIndex]);
    value.feedback[activeStepIndex]["answeredQuestion"] = countedQuestion;
    //console.log("count", check);
    //console.log("handleSelection", value);

    // New data stucture - feedback: {[],[],[],..}
    setFormData({ ...value });
  };

  // Handle slider value change

  const onSliderChange = (
    event,
    subtheme,
    question,
    subThemeindex,
    queIndex
  ) => {
    const value = event.target.value;
    //console.log("check", question);
    let questionSelected = question;
    //console.log("checking", questionSelected)

    // TODO: Refactor this code, merge feedback:{} directly to existing obj
    if (Object.keys(formData).length === 0) {
      let obj = { feedback: [...data] };
      obj.feedback[activeStepIndex].sub_theme[subThemeindex].question[queIndex][
        "value"
      ] = value;

      //console.log("check", obj);
      handleSelection(obj);
    } else if (formData.feedback === null) {
      let objCheck = { ...formData };
      objCheck.feedback = [...data];
      objCheck.feedback[activeStepIndex].sub_theme[subThemeindex].question[
        queIndex
      ] = { ...questionSelected };
      handleSelection(objCheck);
    } else {
      let obj1 = { ...formData };
      questionSelected["value"] = value;

      //console.log("check", questionSelected);
      obj1.feedback[activeStepIndex].sub_theme[subThemeindex].question[
        queIndex
      ] = { ...questionSelected };

      //console.log("obj1", obj1);
      handleSelection(obj1);
    }

    // const questionId = question && question[SAVE_FEEDBACK_BY_KEY];
    // const themeOfClickQuestion =
    //   selectedStepData && selectedStepData[SAVE_FEEDBACK_BY_KEY];
    // const subThemeOfClickQuestion = subtheme && subtheme[SAVE_FEEDBACK_BY_KEY];

    // const newObject = {
    //   [themeOfClickQuestion]: {
    //     [subThemeOfClickQuestion]: { [questionId]: value },
    //   },
    // };

    // const selectedAnswers = formData[FEEDBACK_ANSWERS] || {};
    // const mergeWithOldSelected = mergeDeep(selectedAnswers, newObject);
    // handleSelection(FEEDBACK_ANSWERS, mergeWithOldSelected);
  };

  const noDataBlock = (noDataMsg = "No data found !") => {
    return (
      <div className="font-bold text-slate-400 text-center py-8">
        {noDataMsg}
      </div>
    );
  };

  const getSelectedOption = (question, subtheme, queIndex, subThemeindex) => {
    //console.log("formData", formData);
    if (Object.keys(formData).length === 0 || formData.feedback === null) {
      const selectedArr = 0;
      return selectedArr;
    } else {
      const selectedArr =
        formData?.feedback[activeStepIndex].sub_theme[subThemeindex].question[
          queIndex
        ]?.value;
      //console.log("selectedArr", selectedArr);
      return selectedArr;
    }

    // const themeIdOfClickQuestion =
    //   selectedStepData && selectedStepData[SAVE_FEEDBACK_BY_KEY];

    // const subThemeIdOfQuestion = subtheme && subtheme[SAVE_FEEDBACK_BY_KEY];
    // const questionId = question && question[SAVE_FEEDBACK_BY_KEY];

    // const selectedAnswers = formData[FEEDBACK_ANSWERS] || {};

    // const selectedThemeData = selectedAnswers[themeIdOfClickQuestion];
    // const questionData =
    //   (selectedThemeData && selectedThemeData[subThemeIdOfQuestion]) || {};

    // const selectedArr = questionData && questionData[questionId];
    // return selectedArr;
  };

  const getAddNewQuestionBlock = (subtheme) => {
    return (
      <div className=" text-center p-5">
        <button
          type="button"
          onClick={() => handleShowNewQuestionClick(subtheme.objectId)}
          className={`w-[9rem] px-1 py-2 rounded text-white font-semibold text-xs ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`}
        >
          Add New Question
        </button>
      </div>
    );
  };

  const addNewQuestionTextBox = (subtheme = {}, queIndex) => {
    return (
      <div className="border border-sky-600 bg-sky-100 m-3">
        <p className="text-[15px] pl-12 py-5">
          <span className="flex flex-row items-baseline ">
            {`${alphaArr[queIndex]}. `}
            {
              <EditableText
                id={`${TABLE_QUESTION - subtheme.id}`}
                autoFocus={true}
                name={`text-${TABLE_QUESTION}`}
                placeholder={`Add a new question for ${subtheme.title}`}
                disabled={!isEditModeEnable}
                style={`text-[15px] ${FORM_BG_COLOR}`}
                value={newQuestionText}
                handleOnEnterSave={(val) =>
                  handleNewQuestionSave(val, subtheme)
                }
                handleTextChange={(val) => handleNewQuestionTextChange(val)}
              />
            }
            <div className="flex px-2">
              <button
                className={`px-2 h-8 basis-[19.2857143%] rounded text-white font-semibold text-sm ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`}
                onClick={() => handleNewQuestionSave(newQuestionText, subtheme)}
              >
                Save
              </button>
              <button
                className={`px-2 ml-1 h-8 basis-[19.2857143%] rounded text-white font-semibold text-sm bg-red-700 hover:bg-red-800 `}
                onClick={handleDiscardNewQuestion}
              >
                Exit
              </button>
            </div>
          </span>
          <p className="pl-4 pt-1 text-xs">
            *Press Enter to save or click 'save' button.
          </p>
        </p>
      </div>
    );
  };

  const getQuestionBlock = (subtheme, subThemeindex) => {
    // Return if questions data not configured
    if (!subtheme.question || !subtheme.question.length) {
      // return noDataBlock(`No Questions configure for ${subtheme.title}`);
    }

    // show 'add new qustion' block for selected subtheme only
    const enableNewQuestionBlock =
      showNewQuestionBlockForId &&
      showNewQuestionBlockForId === subtheme.objectId;

    return (
      <div id="questionSectionContainer">
        <div>
          {subtheme.question.map((question, queIndex) => {
            return (
              <div className="flex-row">
                <p className="text-[15px] pl-12 py-3 relative">
                  <div
                    className="flex flex-row items-baseline"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "22px",
                        top: "17px",
                      }}
                    >
                      {/* {`${alphaArr[queIndex]}. `} */}
                      {`Q${questionNumber++}. `}
                    </div>

                    {
                      <EditableText
                        id={question.objectId + queIndex}
                        setIsEditing={setIsEditing}
                        setActiveInputBox={setActiveInputBox}
                        activeInputBox={activeInputBox}
                        tableQuestion={TABLE_QUESTION}
                        subThemeindex={subThemeindex}
                        queIndex={queIndex}
                        columnName={"title"}
                        handleCancelLogic={handleCancelLogic}
                        prevInputValueRef={prevInputValueRef}
                        name={`text-${TABLE_QUESTION}`}
                        placeholder="Question text..."
                        disabled={!isEditModeEnable}
                        style={`text-[15px] font-semibold ${FORM_BG_COLOR}`}
                        value={question.title}
                        handleOnEnterSave={(val) =>
                          handleOnEnterSave(
                            val,
                            question.objectId,
                            TABLE_QUESTION,
                            "title"
                          )
                        }
                        handleTextChange={(value) => {
                          onTextChange(
                            question.objectId,
                            TABLE_QUESTION,
                            "title",
                            value,
                            subThemeindex,
                            queIndex
                          );
                        }}
                      />
                    }
                  </div>
                  {/*   <div className="absolute top-15 right-1 pt-1">
                    {activeInputBox === question.id && (
                      <>
                        <EditableTextButton
                          isEditing={isEditing}
                          handleSaveClick={handleSaveClick}
                          handleCancelClick={handleCancelClick}
                        />
                      </>
                    )}
                  </div> */}
                </p>
                <div className="flex justify-center">
                  <Slider
                    handleChange={(e) =>
                      onSliderChange(
                        e,
                        subtheme,
                        question,
                        subThemeindex,
                        queIndex
                      )
                    }
                    selectedValue={getSelectedOption(
                      question,
                      subtheme,
                      queIndex,
                      subThemeindex
                    )}
                    isDisable={isStatusSubmitted()}
                    activeStepIndex={activeStepIndex}
                    style={"w-10/12"}
                  />
                </div>
                {queIndex < subtheme.question.length - 1 && (
                  <div className="mx-12 my-5">
                    <hr />
                  </div>
                )}
              </div>
            );
          })}
          {isEditModeEnable &&
            enableNewQuestionBlock &&
            addNewQuestionTextBox(subtheme, subtheme.question.length)}
        </div>

        {isEditModeEnable &&
          !enableNewQuestionBlock &&
          getAddNewQuestionBlock(subtheme)}
      </div>
    );
  };

  const getSubThemeBlock = () => {
    // Return if sub_theme data not configured
    if (!selectedStepData.sub_theme || !selectedStepData.sub_theme.length) {
      return noDataBlock(
        `No sub themes configure for ${selectedStepData.title}`
      );
    }

    // Reset question number to 1 while re-rendering
    questionNumber = 1;

    return (
      <div id="subThemeContainer" className="pt-3">
        {selectedStepData.sub_theme.map((subtheme, index) => {
          return (
            <div
              id={`subtheme-${index}`}
              className="bg-slate-50 rounded-lg px-3"
            >
              <div className="px-3">
                <h3 className="font-bold text-lg text-slate-600">
                  {/* {`${index + 1}. ${subtheme.title}`} */}
                  <div
                    className="relative flex-row items-baseline"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        left: "-12px",
                        top: "32px",
                      }}
                      className="text-[15px] bg-slate-50"
                    >{`Sub-Pillar ${activeStepIndex + 1}.${index + 1}`}</div>

                    {
                      <EditableText
                        index={index}
                        id={subtheme.id}
                        title={true}
                        setIsEditing={setIsEditing}
                        setActiveInputBox={setActiveInputBox}
                        activeInputBox={activeInputBox}
                        name={`text-${TABLE_SUB_THEME}`}
                        placeholder="asd"
                        disabled={!isEditModeEnable}
                        style={`text-[15px] ${FORM_BG_COLOR}`}
                        value={subtheme.title}
                        handleOnEnterSave={(val) =>
                          handleOnEnterSave(
                            val,
                            subtheme.objectId,
                            TABLE_SUB_THEME,
                            "title"
                          )
                        }
                        handleTextChange={(value) => {
                          onTextChange(
                            subtheme.objectId,
                            TABLE_SUB_THEME,
                            "title",
                            value,
                            index,
                            null
                          );
                        }}
                      />
                    }
                  </div>
                </h3>
                <div className="text-sm text-slate-700">
                  {/* {subtheme.description} */}
                  <div
                    className="relative flex flex-row items-baseline"
                    style={{ flexWrap: "wrap" }}
                  >
                    {
                      <EditableText
                        id={subtheme.objectId}
                        description={true}
                        setIsEditing={setIsEditing}
                        setActiveInputBox={setActiveInputBox}
                        activeInputBox={activeInputBox}
                        name={`text-${TABLE_SUB_THEME}`}
                        placeholder="asd"
                        disabled={!isEditModeEnable}
                        style={`text-[15px] ${FORM_BG_COLOR}`}
                        value={subtheme.description}
                        handleOnEnterSave={(val) =>
                          handleOnEnterSave(
                            val,
                            subtheme.objectId,
                            TABLE_SUB_THEME,
                            "description"
                          )
                        }
                        handleTextChange={(value) => {
                          onTextChange(
                            subtheme.objectId,
                            TABLE_SUB_THEME,
                            "description",
                            value,
                            index,
                            null
                          );
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
              {getQuestionBlock(subtheme, index)}
            </div>
          );
        })}
      </div>
    );
  };

  const handleBackStepBtnClick = () => {
    onStepClickChange(activeStepIndex - 1);
  };

  // Move to next step
  const moveToNextStep = () => {
    onStepClickChange(activeStepIndex + 1);
  };

  const onStepClickChange = (index) => {
    handleOnStepClick(index);
    questionNumber = 1;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleOnError = () => {
    setLoader(false);
    setAlertMesssage(ERROR_MESSAGE);
    setSeverity("error");
  };

  const handleOnSuccess = (msg = "Done!") => {
    setLoader(false);
    setAlertMesssage(msg);
    setSeverity("success");
  };

  // return feedback obj which having values only
  const filterFeedbackOnlyWithValues = (row) => {
    const feedback = (row.feedback || []).map((item) => {
      const sub = (item.sub_theme || []).filter((subTheme) => {
        const index = (subTheme.question || []).filter((queValue) => {
          return !!queValue?.value;
        });
        if (index.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (sub.length > 0) {
        item.sub_theme = sub;
        return item;
      }
    });

    row.feedback = feedback.filter((row) => !!row);
    return row;
  };

  /*  useEffect(()=>{
    if(formData?.objectId && activeStepIndex===0){
      moveToNextStep()
    }
  }, [activeStepIndex]) */

  const onLoaderModalClose = () => {
    setAlert(false);
    setLoader(false);
    setAlertMesssage(null);
    setSeverity(null);

    // Redirect only if feedback submitted successfully.
    if (severity === "success") {
      // navigate(-1);
      // Pass current submitted form data to feedback page to automatically show map on submit
      console.log("formData::: ", formData);
      if (formData && formData.status === SUBMIT_STATUS) {
        const filterFormData = filterFeedbackOnlyWithValues(formData);
        navigate(`/${FEEDBACK_ROUTE}`, { state: filterFormData });
      } else if (formData && exit === true) {
        const filterFormData = filterFeedbackOnlyWithValues(formData);
        navigate(`/${FEEDBACK_ROUTE}`);
      } else if (formData?.objectId) {
        //navigate(`/feedback/${formData.objectId}`);
        window.history.replaceState(null, "", `/feedback/${formData.objectId}`);
      }
      moveToNextStep();
    }
  };

  //console.log("exit", exit);
  //console.log("isLoading", isLoading);

  const setStateBeforeUpload = () => {
    setAlert(true);
    setAlertMesssage(UPLOAD_MESSAGE);
    setLoader(true);
  };

  // Submit form request to backend
  const onSubmit = (reqBody, isSuccessMsg) => {
    submitFeedback(reqBody)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          // Update current form state to state
          console.log("onSubmitelse");
          setFormData({ ...formData, ...data });
          handleOnSuccess(isSuccessMsg || SUBMIT_SUCCESS_MESSAGE);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  // Update form request to backend
  const onUpdate = (reqBody, objectId, isSuccessMsg) => {
    // Update current form state to state
    // setFormData({ ...formData, ...reqBody });

    updateFeedback(reqBody, objectId)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          // console.log(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
          // Update current form state to state
          setFormData({ ...formData, ...data });
          handleOnSuccess(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  const handleFormSubmit = (btnClick) => {
    const objectId = formData["objectId"];
    const formStatus = formData["status"];

    // const reqBody = {
    //   [THEME_LABEL]: formData[THEME_LABEL],
    //   [FEEDBACK_ANSWERS]: formData[FEEDBACK_ANSWERS],
    //   [FEEDBACK_STATUS]: formData[FEEDBACK_STATUS],
    // };
    //console.log("handleFormSubmit", formData);

    const reqBody = { ...formData };

    try {
      // Set upload flag in state on submit like [isloading,alertMsg etc]
      setStateBeforeUpload();

      if (btnClick === "submit") {
        // Form with 'submitted' status
        reqBody.status = SUBMIT_STATUS;
        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId, SUBMIT_SUCCESS_MESSAGE);
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody);
        }
      } else if (btnClick === "save" || btnClick === "exit") {
        if (btnClick === "exit") {
          setExit(true);
        }
        // Form with 'pending' status
        reqBody.status = UPDATE_STATUS;
        /* navigate(`/feedback/${row.objectId}`) */
        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId);
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody, SAVE_CONTINUE_SUCCESS_MESSAGE);
        }
      }
    } catch (error) {
      console.log("error while Uploading form:", error);
      handleOnError("Error while Uploading form");
    }
  };

  const getInfoIcon = () => {
    return (
      <div className="text-indigo-400 pl-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </div>
    );
  };

  const getFormActionButtons = () => {
    const isDisable = formData["status"] === SUBMIT_STATUS;

    const disableClass = "opacity-30 disabled:cursor-not-allowed";
    const btnSize = "w-96";
    const tooltipClass = "tooltip tooltip-left";

    // Hide 'Save and Continue to The Next Pillar' button on last pillar selection
    const isContinueToNextPillarBtnDisable =
      data && activeStepIndex + 1 < data.length ? true : false;

    return (
      <div className="w-10/12 flex flex-col justify-end items-end py-6 border-t border-50">
        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip={
            "Clicking on this button will save your responses for this pillar and you will continue to the next pillar."
          }
        >
          {isContinueToNextPillarBtnDisable && (
            <>
              <button
                className={`${btnSize} px-3 py-3 mt-2 rounded text-white font-semibold text-sm ${
                  themeDefault.btn_bg_color
                } hover:${themeDefault.btn_bg_hover} ${
                  isDisable && disableClass
                }`}
                onClick={() => handleFormSubmit("save")}
                disabled={isDisable}
              >
                Save and Continue to The Next Pillar
              </button>
              {getInfoIcon()}
            </>
          )}
        </div>

        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip="You can save your answers now and come back later at any point of time in future to continue this session."
        >
          <button
            className={`${btnSize} px-3 py-3 mt-2 rounded font-semibold text-sm text-blue-600 border-2 border-blue-500 hover:bg-blue-600 hover:text-white ${
              isDisable && disableClass
            }`}
            onClick={() => handleFormSubmit("exit")}
            disabled={isDisable}
          >
            Save my self-assessment and continue later
          </button>
          {getInfoIcon()}
        </div>
        <div
          className={`flex items-center ${tooltipClass}`}
          data-tip={`This is the button to submit all your answers and view results. Once you have submitted the answers, the answers and result can't be changed. You can always start a new self assessment in another session.`}
        >
          <button
            className={`${btnSize} px-3 py-3 mt-2 rounded text-white font-semibold text-sm bg-green-700 hover:bg-green-600 ${
              isDisable && disableClass
            }`}
            onClick={() => handleFormSubmit("submit")}
            disabled={isDisable}
          >
            Finish my self-assessment and visualise the results
          </button>
          {getInfoIcon()}
        </div>
      </div>
    );
  };

  const formBodyContainer = () => {
    return (
      <h3 className="text-xl p-5 text-left">
        <div className="font-semibold">
          {`PILLAR 0${
            selectedStepData.sequence
          } : ${selectedStepData.title?.toUpperCase()}`}
        </div>
        <div className="text-sm text-slate-700 pt-6">
          {selectedStepData.description}
        </div>
        <div id="subThemeContainer" className="pt-7">
          <div className="font-semibold text-lg">
            {
              "You need to answer a series of 20 questions to identify your organisation’s strengths and weaknesses for volunteering. There are no right or wrong answers. Just be as honest as you can. We encourage you to answer all questions for an accurate result."
            }
          </div>
          {getSubThemeBlock()}
        </div>
      </h3>
    );
  };

  const toggleEditModeIcon = () => {
    const iconBgColor = isEditModeEnable ? "bg-yellow-300" : "bg-blue-300";
    const shadeColorFrom = isEditModeEnable
      ? "from-yellow-600"
      : "from-blue-500";
    const shadeColorTo = isEditModeEnable ? "to-gray-600" : "to-blue-600";

    return (
      <div className={`${stickyClassEditEye}`} onClick={handleOnEditModeClick}>
        <div className="relative group">
          <div
            className={`absolute -inset-1 bg-gradient-to-r ${shadeColorFrom} ${shadeColorTo} rounded-full blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`}
          ></div>
          <div
            className={`relative p-5 ${iconBgColor} ring-8 ring-gray-900/5 rounded-full leading-none flex items-top justify-start space-x-6`}
          >
            {isEditModeEnable ? <IconPencil color={"white"} /> : <IconEye />}
          </div>
        </div>
      </div>
    );

    return (
      <div
        className={`rounded-full p-2 ${stickyClassEditEye} ${
          isEditModeEnable ? "bg-yellow-500" : "bg-blue-400"
        }`}
        onClick={handleOnEditModeClick}
      >
        {isEditModeEnable ? <IconPencil /> : <IconEye />}
      </div>
    );
  };

  return (
    <div className="mx-8 my-1 w-full">
      {isAdminRole() && toggleEditModeIcon()}
      {formHeading()}
      {stepContainer()}
      <div className="divider pt-5"></div>
      {formBodyContainer()}
      {getFormActionButtons()}
      {showAlert && (
        <BackdropLoader
          isOpen={showAlert}
          isLoading={isLoading}
          message={alertMesssage}
          severity={severity}
          onClose={onLoaderModalClose}
        />
      )}
    </div>
  );
};

export default NewFeedbackForm;

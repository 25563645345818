// Use for Us cluster
// const BACKENDLESS_REST_DOMAIN = "api.backendless.com";

// Use for Europe cluster
const BACKENDLESS_REST_DOMAIN = "eu-api.backendless.com";

// Add Backendless key/config for : EOSE Production
const BACKENDLESS_SUBDOMAIN = "scenictalk.backendless.app";
const APP_ID = "6ACBC77B-C2B2-8DA5-FFE2-B2551F5BD600";
const REST_API_KEY = "4F5C545D-21C3-49FC-B62C-28DD5BC4F5F8";

// Add url of privacy policy pdf
export const PRIVACY_POLICY_URL = "https://eose.org/privacy-policy-conditions";

// This is home page url for eose main application
export const EOSE_HOME_APP_URL = "https://www.eose.org/v4v-sport-organisations-toolkit-resources/";

// domain to download file. Use it as per cluster used while creating app/ or can check
const FILE_DOWNLOAD_DOMAIN_NORTHAME = "backendlessappcontent.com"; // North American cluster
const FILE_DOWNLOAD_DOMAIN_EUROPE = "eu.backendlessappcontent.com"; // European Union cluster
const FOLDER_ROOT = "feedback_actions_list"; // This is folder name where all files are stored on Backendless under files section
const GROUPS_PATH = `${FOLDER_ROOT}/stakeholders`;
const ACTIONS_PATH = `${FOLDER_ROOT}/factors`;

//Vairable to store user data in local storage
export const USER_STORAGE = "user_info";

export const BASE_API = `https://${BACKENDLESS_REST_DOMAIN}/${APP_ID}/${REST_API_KEY}`;

export const login_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/login`;
export const signup_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/register`;
export const forgotPassword_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/restorepassword/`;

export const topics_api = BASE_API + "/data/Topics?relationsDepth=2";
export const groups_api = BASE_API + "/data/Groups?relationsDepth=2";


export const theme_api = BASE_API + "/data/theme?relationsDepth=2";

// Get particular theme data by title
export const theme_by_title_api = (id) => BASE_API + `/data/Theme?where=id=${id}`;

export const question_view_api = BASE_API + "/data/questions";
export const question_api = BASE_API + "/data/question"
export const question_by_id_api = (id) => `${question_api}/${id}`

export const sub_theme_api = BASE_API + "/data/subTheme"
export const subtheme_by_id_api = (id) => `${sub_theme_api}/${id}`

// Get particular theme data by title
export const sub_theme_by_title_api = (id) => BASE_API + `/data/subTheme?where=id=${id}`;

/* Api req params
    {topic: 'string', group: 'json{}', feedback: 'json{}', status:'string' }
 */
export const answers_api = `${BASE_API}/data/feedback`;
export const update_answers_api = (id) => `${answers_api}/${id}`;

export const get_answer_api = (id) => `${answers_api}/${id}`;
export const delete_answer_by_id__api = (id) => `${answers_api}/${id}`;

export const category_api = `${BASE_API}/data/Categories`;

// Users table api
export const users_api = `${BASE_API}/data/users`;
export const updateUser_api = `https://${BACKENDLESS_SUBDOMAIN}/api/services/userStatus/changeUserStatus`;

// return file Download url
// File url format:  "https://eu.backendlessappcontent.com/<application id>/<REST-API-key>/files/<path>/<file name>"
export const file_download_url_groups = (file_name) =>
  `https://${FILE_DOWNLOAD_DOMAIN_EUROPE}/${APP_ID}/${REST_API_KEY}/files/${GROUPS_PATH}/${encodeURI(
    file_name
  )}`;
export const file_download_url_actions = (file_name) =>
  `https://${FILE_DOWNLOAD_DOMAIN_EUROPE}/${APP_ID}/${REST_API_KEY}/files/${ACTIONS_PATH}/${encodeURI(
    file_name
  )}`;
